import { isProductDisabled } from "utils/ProductUtils";
import EansPopover from "./EansPopover";

import productCardStyle from "./style/ProductCard.module.css";
import { dateEasyRead } from "utils/DateStyler";
import { Alert, AlertTitle } from "@mui/material";

const StoreProductCard = (props) => {
    const { product } = props;
    const { outline } = props;
    var containerStyle = productCardStyle.productInfoContainer;

    if (!outline) {
      containerStyle = productCardStyle.productInfoContainerClean;
    }
      

  
    if (product === null) {
      return <NoProductFound />;
    } else {
      return (
        <>
          <div className={containerStyle}>
            <span className={productCardStyle.vendor}>
              {product.product.productInfo.vendor}
            </span>
            <span className={productCardStyle.productName}>
              {product.product.productInfo.name}
            </span>
            <span className={productCardStyle.productInfoElement}>
              <i
                className="fa-sharp fa-solid fa-barcode"
                id={productCardStyle.icon}
              ></i>
              <b> {product.product.ean}</b>
              <b>
                <EansPopover product={product} />
              </b>
            </span>
            <span className={productCardStyle.productInfoElement}>
              <i
                className="fa-solid fa-boxes-packing"
                id={productCardStyle.icon}
              ></i>{" "}
              <b>{product.product.productInfo.volume}</b>
            </span>
            <span className={productCardStyle.productInfoElement}>
              <i
                className="fa-solid fa-table-cells-large"
                id={productCardStyle.icon}
              ></i>{" "}
              <b>{product.planogram.name}</b>
            </span>
            <br />
            {product.supplier !== null && (
              <span className={productCardStyle.productInfoElement}>
                <i className="fa-solid fa-truck" id={productCardStyle.icon}></i>
                <b>{product.supplier.name}</b>
              </span>
            )}
  
            {product.expire !== null && (
              <span className={productCardStyle.expireElement}>
                Срок на годност<br />{" "}
                <h3>{dateEasyRead(product.expire.expireDate)}</h3>
              </span>
            )}
            {product.expire === null && !isProductDisabled(product) && (
              <span
                className={productCardStyle.expireElement}
                id={productCardStyle.noQuantity}
              >
                <i>Няма наличност от артикула!</i>
              </span>
            )}
            {product.expire === null && isProductDisabled(product) && (
              <span
                className={productCardStyle.expireElement}
                id={productCardStyle.noQuantity}
              >
                <i>Деактивиран артикул!</i>
              </span>
            )}
          </div>
        </>
      );
    }
  };

  const NoProductFound = (productId) => {
    return (
      <div className="mb-2">
        <Alert variant="standard" severity="error">
          <AlertTitle>
            <b>Артикулът не е намерен!</b>
          </AlertTitle>
          Артикула който търсите не е намерен в системата.
          <br />
          <br />
          Моля, проверете дали сте въвели правилния EAN или се свържете с
          администратора.
        </Alert>
      </div>
    );
  };

  export default StoreProductCard;