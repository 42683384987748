import React from "react";
import { useEffect } from "react";
import style from "../components/style/EansPopover.module.css";
import { Popover } from "bootstrap";

const EansPopover = ({ product }) => {
  useEffect(() => {
    document.querySelectorAll('[data-bs-toggle="popover"]').forEach((el) => {
      new Popover(el);
    });
  }, []);

  return (
    <>
      {product.product.eans.length > 0 ? product.product.eans[0].ean : "---"}
      {"  "}
      {product.product.eans.length > 1 && (
        <button
          type="button"
          className={style.popoverListButton}
          data-bs-toggle="popover"
          data-bs-title="EAN кодове"
          data-bs-html="true"
          data-bs-trigger="focus"
          data-bs-content={product.product.eans.map((e) => e.ean).join("<br>")}
        >
          <i className="fa-solid fa-list"></i>
          {product.product.eans.length}
        </button>
      )}
    </>
  );
};

export default EansPopover;
