import { Avatar, useMediaQuery } from "@mui/material";
import style from "./style/ProfileCorner.module.css";
import { getUser } from "utils/auth";
import Badges from "./Badges";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import LogoutIcon from "@mui/icons-material/Logout";
import AccountBox from "@mui/icons-material/AccountBox";
import StorefrontIcon from "@mui/icons-material/Storefront";

import { useState } from "react";
import { Link, NavLink } from "react-router-dom";

export default function ProfileCorner() {
  const user = getUser();
  const fullName = `${user?.firstName} ${user?.lastName}`;
  const matches = useMediaQuery("(min-width:900px)");

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // @ts-ignore
  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }
  return (
    <div className={style.mainContainer}>
      {/* <Badges /> */}
      <Avatar
        className={style.avatar}
        {...stringAvatar(fullName)}
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      />
      <Menu
        id="demo-positioned-menu"
        className={style.menu}
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={handleClose}>
          <NavLink className={style.menuItem} to="/profile">
            <AccountBox className={style.menuIcon}/>
            Профил
          </NavLink>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <NavLink className={style.menuItem} to="/stores">
            <StorefrontIcon className={style.menuIcon}/>
            Обекти
          </NavLink>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <NavLink className={style.menuItem} to="/logout">
            <LogoutIcon className={style.menuIcon}/>
            Изход
          </NavLink>
        </MenuItem>
      </Menu>
      {matches && (
        <div className={style.nameContainer}>
          <h3 className={style.name}>{fullName}</h3>
          <h4 className={style.email}>{user?.email}</h4>
        </div>
      )}
    </div>
  );
}
