import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

export default function BackButton(props) {
  const navigate = useNavigate();
  console.log("BackButton props.to:", props.to);

  if(props.to !== null && props.to !== undefined){
    return (
      <Link className="btn btn-info mx-1 btn-sm" to={props.to}>
        Назад
      </Link>
    );
  } else {
    return (
      <Link className="btn btn-info mx-1 btn-sm" onClick={() => navigate(-1)} to={""}>
        Назад
      </Link>
    );
  }

}
